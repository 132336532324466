/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-c-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 5a3 3 0 000 6h10a3 3 0 100-6zm.5 2.5h9a.5.5 0 010 1h-9a.5.5 0 010-1"/>',
    },
});
